import Box from '@mui/material/Box';
import {
  MenuItemLink,
  useSidebarState,
} from 'react-admin';
import SubMenu from './SubMenu';
import { useState } from 'react';

type MenuName = 'menuConsoles';

export const Menu = () => {
  const [state, setState] = useState({
    menuConsoles: false,
  });

  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };


  return (
    <Box sx={{
      width: open ? 300 : 50,
      marginTop: 1,
      marginBottom: 1,
    }}
    >
      <SubMenu
        handleToggle={() => handleToggle('menuConsoles')}
        isOpen={state.menuConsoles}
        name='Consoles'
        icon={
          <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' fill='currentColor'
               className='bi bi-braces-asterisk' viewBox='0 0 16 16'>
            <path fill-rule='evenodd'
                  d='M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6M14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6M7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5z' />
          </svg>
        }
        dense={false}
      >
        <MenuItemLink
          to='/accounts/google'
          state={{ _scrollToTop: true }}
          primaryText='Google'
        />
        <MenuItemLink
          to='/accounts/apple'
          state={{ _scrollToTop: true }}
          primaryText='Apple'
        />
        <MenuItemLink
          to='/accounts/amazon'
          state={{ _scrollToTop: true }}
          primaryText='Amazon'
        />
      </SubMenu>
      {/*<MenuItemLink*/}
      {/*  to='/accounts'*/}
      {/*  primaryText='Accounts'*/}
      {/*  leftIcon={*/}
      {/*    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">*/}
      {/*      <path*/}
      {/*        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />*/}
      {/*    </svg>*/}
      {/*  }*/}
      {/*/>*/}
      <MenuItemLink
        to='/sim-cards'
        primaryText='SIM cards'
        leftIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-sim-fill"
               viewBox="0 0 16 16">
            <path
              d="M5 4.5a.5.5 0 0 1 .5-.5h2v2H5zM8.5 6V4h2a.5.5 0 0 1 .5.5V6zM5 7h6v2H5zm3.5 3H11v1.5a.5.5 0 0 1-.5.5h-2zm-1 0v2h-2a.5.5 0 0 1-.5-.5V10z" />
            <path
              d="M3.5 0A1.5 1.5 0 0 0 2 1.5v13A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5V3.414a1.5 1.5 0 0 0-.44-1.06L11.647.439A1.5 1.5 0 0 0 10.586 0zm2 3h5A1.5 1.5 0 0 1 12 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 11.5v-7A1.5 1.5 0 0 1 5.5 3" />
          </svg>
        }
      />
      <MenuItemLink
        to='/tasks'
        primaryText='Task list'
        leftIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-list-task"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z" />
            <path
              d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
            <path fill-rule="evenodd"
                  d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z" />
          </svg>
        }
      />
    </Box>
  );
};

export default Menu;
